<template>
  <div>

    <!-- Header Alert -->
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
        Jadwal <strong>Libur</strong> {{ doctor.name }}
      </div>
      <b-button
        squared
        variant="success"
        @click="btnAddOnClick"
        v-b-modal.modal-form
        v-if="manipulateBtn == true"
      >Tambah</b-button>
    </b-alert>

    <!-- Holiday List -->
    <div class="row">
      <div class="col-md-12">
        <Card>
          <template v-slot:body>
            <Table
              :fields="fields"
              :items="items"
              :hasDoctor="true"
              :perPage="perPage"
              :currentPage="currentPage"
              :totalRows="totalRows"
              @pageOnClick="pageOnClick"
              @btnEditOnClick="btnEditOnClick"
              @btnDeleteOnClick="btnDeleteOnClick"
            />
          </template>
        </Card>
      </div>
    </div>

    <!-- Modal Form -->
    <Form
      :form="form"
      :route="route"
      :editTarget="editTarget"
      :hasDoctor="true"
      @formOnSubmit="formOnSubmit"
    />

  </div>
</template>

<script>

import Card from "@/view/content/Card.vue"
import Form from '@/component/holidays/Form.vue'
import Table from '@/component/holidays/Table.vue'
import module from '@/core/modules/CrudModule.js'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

export default {

  components: {
    Card,
    Form,
    Table
  },

  data() {
    return {      
      // Form
      form: {
        doctor_id: '',
        date: ''
      },
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 0,
      // Form Route
      route: 'holidays',
      // Table Head
      fields: [
        {
          key: "number",
          label: '#',
          sortable: true,
        },        
        {
          key: "date",
          label: "Tanggal",
          sortable: true,
        },
        {
          key: "notes",
          label: "Keterangan",
          sortable: true,
        },
        { key: "actions", label: "Aksi" },
      ],
      // Table Items
      items: [],
      // Doctor Data
      doctor: {},
      // Other      
      editTarget: '',
      // access management
      manipulateBtn : false,
    }
  },

  methods: {

    pageOnClick(page) {
      this.currentPage = page
      this.pagination()
    },

    async pagination() {
      let response = await module.paginate(`holidays/get-by-doctor/${this.$route.params.id}`, `?page=${this.currentPage}&page_size=${this.perPage}`)
      let pagination = response.meta.pagination
      this.items = response.data
      this.totalRows = pagination.total
    },

    async getDoctorData() {
      this.doctor = await module.get('doctors/' + this.$route.params.id)
      this.form.doctor_id = this.doctor.id
      // If Data Not Found
      if (this.doctor == null) {
        // Redirect To List
        this.$router.push('/doctors/list')
      }
    },

    btnAddOnClick() {
      this.editTarget = ""
    },

    async btnEditOnClick(id) {
      this.editTarget = id.toString()
      this.form = await module.get(`holidays/${id}`)
      this.form['_method'] = 'put'
    },

    async btnDeleteOnClick(id) {
      // Delete Data
      let result = await module.delete(`holidays/${id}`)
      // If Deleted
      if (result) {
        this.pagination()
      }
    },

    formOnSubmit() {
      this.pagination()
    },

    // access management
    async setActiveMenu(){

     let access_right_user = window.localStorage.getItem("access_right_display")
     let access_right = JSON.parse(access_right_user)
    
    let a
    for(a = 0; a < access_right.length; a++){
      
      
      if(access_right[a] == "4004"){
        this.manipulateBtn = true
      }
      
    }
    },

  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dokter", route: "" },
      { title: "Daftar Dokter", route: "/doctor/list" },
      { title: "Jadwal Libur" },
    ])
    // Get Table Data
    this.pagination()
    // Get Doctor Data
    this.getDoctorData()
    // access management
    this.setActiveMenu()
  },

}

</script>